import * as d3 from "d3";

const HEIGHT_BAR = 20;
const HEIGHT_OFFSET_LOGS = 70;
const COLOR_BASE = "black";
const COLOR_PROGRESS = "tomato";
const ANIMATION_DURATION = 100;

function renderLogLine(width, height, display, line, ypos, fontWeight = "normal") {
  const textOffset = line.startsWith("- ") ? 30 : 20;
  display
    .append("text")
    .attr("font-weight", fontWeight)
    .style("color", "#444")
    .attr("x", -width / 2 + textOffset)
    .attr("y", -height / 2 + HEIGHT_BAR + HEIGHT_OFFSET_LOGS)
    .attr("dy", `${ypos}em`)
    .text(line);
}

function renderLogs(width, height, display, logs) {
  d3.selectAll(".progress-logs").remove();
  display = d3.select("#graphSvg")
    .append("g")
    .attr("class", "progress-logs");

  renderLogLine(width, height, display, "Logs", 0, "bold");

  for (let i = 0; i < logs.length; i++) {
    renderLogLine(width, height, display, logs[i], i+1);
  }
}

export function renderProgress(width, height) {
  const bar = d3.select("#graphSvg")
    .append("g")
      .attr("class", "progress-bar")
      .attr("width", width)
      .attr("height", HEIGHT_BAR);

  bar.append("rect")
          .attr("class","pb-base")
          .attr("width", width)
          .attr("height", HEIGHT_BAR/2)
          .attr("x", -width / 2)
          .attr("y", -height / 2)
          .style("fill", COLOR_BASE);

  const logs = ["Initializing data download"];

  const logDisplay = d3.select("#graphSvg")
    .append("g")
      .attr("class", "progress-logs");

  renderLogs(width, height, logDisplay, logs);
  return { bar, logDisplay, logs };
}

export function removeProgress() {
  d3.selectAll(".progress-bar .pb-base").remove();
  d3.selectAll(".progress-bar .pb-fill").remove();
  d3.selectAll(".progress-bar text").remove();
  d3.selectAll(".progress-logs").remove();
}

export function animateProgress(elements, width, height, logInfo, fromPct = null, toPct = null) {
  const { bar, logDisplay, logs } = elements;

  if (fromPct !== null && toPct !== null) {
    d3.selectAll(".progress-bar .progress-bar").remove();
    d3.selectAll(".progress-bar text").remove();
    const fromWidth = width*(fromPct/100);
    const toWidth = width*(toPct/100);
    bar.append("rect")
            .attr("class","pb-fill")
            .attr("x", -width / 2)
            .attr("y", -height / 2)
            .attr("height", HEIGHT_BAR/2)
            .style("fill", COLOR_PROGRESS)
            .attr("width", fromWidth)
            .transition()
            .duration(ANIMATION_DURATION)
            .ease(d3.easeLinear)
            .attr("width", toWidth);
  }

  logs.unshift(logInfo);
  renderLogs(width, height, logDisplay, logs);
}

// var _progress = renderProgressBar(options);

// setInterval(function(){ options.progressStartWidth = options.progressEndWidth; options.progressEndWidth = Math.floor((Math.random() * options.width) ); animateProgressBar(_progress,options);},2000);
