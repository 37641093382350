import { CATEGORY } from "./wikidata";

export const CHARGE_FORCE = 17500;
export const DEFAULT_BATCH_SIZE = 50;

export const ENKOSI_URL = 'https://enkosi.co.uk';
export const SPARQL_ENDPOINT_URL = 'https://query.wikidata.org/sparql';
export const WIKI_ENDPOINT_URL = "https://en.wikipedia.org/w/api.php?origin=*&format=json";
export const BASE_SEARCH_URL = `https://www.wikidata.org/w/api.php?origin=*&format=json&action=query&list=search&srsearch=`;
export const WIKIDATA_SOURCE_IMG_URL = 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Wikidata_Stamp_Rec_Dark.svg/2880px-Wikidata_Stamp_Rec_Dark.svg.png';

export const NODE_STROKE_COLOR = "#fff";
export const NODE_STROKE_WIDTH = 2;
export const NODE_ICON_SIZE = 24;
export const NODE_RADIUS = 22;

export const NODE_LABEL_FONT_SIZE = 28;
export const NODE_LABEL_OFFSET = 20;

export const MARKER_POS_RELATIVE = 18;
export const MARKER_POS_NORMAL = 25;


export const LINK_STROKE_WIDTH_RELATIONSHIP = 2;
export const LINK_STROKE_WIDTH_DEFAULT = 1;
export const LINK_STROKE_OPACITY = 0.6;
export const LINK_INFO_FONT_SIZE = 18;

export const NEUTRAL_COLOR = "#999";
export const ERROR_COLOR = "#b80000";
export const DEFAULT_COLOR = "#9D00A0";

export const DEFAULT_ICON = "\uf128";

export const LINK_KEY_SEP = "::";

export const ICON_CODES = { "_info": { code: "\uf059", color: "#1300A0" } }
ICON_CODES[CATEGORY.REAL_HUMAN.label] = { code: "\uf007", color: "#9D00A0" };
ICON_CODES[CATEGORY.FICTIONAL_HUMAN.label] = { code: "\uf4ff", color: "#d502ae" };
ICON_CODES[CATEGORY.AWARD.label] = { code: "\uf091", color: "#a07300" };
ICON_CODES[CATEGORY.WORK.label] = { code: "\uf518", color: "#9D00A0" };
ICON_CODES[CATEGORY.ORGANIZATION.label] = { code: "\uf0ac", color: "#a00000" };
ICON_CODES[CATEGORY.COMPANY.label] = { code: "\uf0b1", color: "#00a013" };
ICON_CODES[CATEGORY.EDUCATIONAL.label] = { code: "\uf19c", color: "#00a090" };

export const INFO_DATA = `
Usage Guide:
- Click a node's label to expand the graph
- Hover over a node to show more information
- Click a node to show it's info and options menu
- Hover/click on link info icon for relationship info

Controls:
- Scroll to zoom
- Drag to pan

Reload the page for a random starting person!

To search from the URL, you can either:
- bluelinkmapper.com/?s=beyonce
- bluelinkmapper.com/?w=https://en.wikipedia.org/wiki/Beyonce
`;

export const DEFAULT_NODE_IDS = [
    "Q244674",  // Benedict Cumberbatch
    "Q37079", // Tom Cruise
    "Q36153", // Beyonce
    "Q170572", // Alec Baldwin
    "Q34460", // Gwyneth Paltrow
    "Q3304418", // Meghan Markle
    "Q4886439", // Ben Shapiro
    "Q14540", // Tina Fey
    "Q713479", // Jimmy Savile
    "Q189489", // Zendaya
    "Q13424289", // Edward Snowden
    "Q7088045", // Olivia Colman
    "Q5556756", // Ghislaine Maxwell
    "Q40321", // Dave Chappelle
    "Q1816737", // Robert Kardashian
    "Q8027", // Martin Luther King Jr
    "Q11459", // Serena Williams
    "Q42", // Douglas Adams
    "Q180589", // Boris Johnson
    "Q24572712", // Dominic Cummings
    "Q7259", // Ada Lovelace
    "Q540608", // Rashida Jones
    "Q221364", // Jaden Smith
    "Q38370", // Henry VIII
    "Q11812", // Thomas Jefferson
    "Q859", // Plato
    "Q302", // Jesus
    "Q1035", // Charles Darwin
    "Q187324", // Zelda Fitzgerald
    "Q47899", // Paris Hilton
    "Q9061", // Karl Marx
    "Q36290", // Diana Ross
    "Q234644", // Elizabeth Olsen
    "Q47213", // Warren Buffett
    "Q16146870", // Steve Bannon
    "Q676094", // Drew Barrymore
    "Q160278", // John Rockefeller
    "Q1395624", // Cara Delevingne
    "Q34660", // JK Rowling
    "Q382420", // Damon Wayans
    "Q213512", // Bill Cosby
    "Q53944", // Rupert Murdoch
    "Q317521", // Elon Musk
    "Q170581", // Nancy Pelosi
    "Q239411", // Ivanka Trump
    "Q6294", // Hillary Clinton
    "Q42869", // Nicolas Cage
    "Q13909", // Angelina Jolie
    "Q28493", // Ralph Fiennes
    "Q10853588", // Kamala Harris
    "Q16769592", // Amal Clooney
];