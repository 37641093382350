const isObject = (v) => typeof v === 'object' && v !== null;

function flattenObjectValues(obj) {
  return Object.values(obj).map((value) => isObject(value) ? flattenObjectValues(value) : value).flat();
}

export const WIKI_DATA = {
  types: {
    HUMAN: "Q5",
    human: {
      MALE: "Q6581097",
      FEMALE: "",
    },
    AWARD: "Q618779",
    organization: {
      _: "Q43229",
      SERVICE_PROVIDER: "Q2169973",
      CULTURAL: "Q29918292",
      government: {
        _: "Q2659904",
        AGENCY: "Q327333",
      },
      institution: {
        _: "Q178706",
        CULTURAL: "Q3152824",
        NATIONAL: "Q7137661",
      },
      NON_PROFIT: "Q163740",
      CHARITABLE: "Q708676",
      ADVOCACY: "Q431603",
      COMPANY: "Q783794",
      voluntary: {
        _: "Q48204",
        LEARNED: "Q955824",
      },
      educational: {
        _: "Q5341295",
        INSTITUTION: "Q2385804",
        DIVISION: "Q23005223",
        higher: {
          _: "Q38723",
          UNIVERSITY: "Q3918",
          COLLEGE: "Q189004",
          COLLEGIATE_UNIVERSITY: "Q3354859",
          UNIVERSITY_COLLEGE: "Q15407956",
          CONSTITUENT_COLLEGE: "Q62079009",
        },
      },
    },
  },
  properties: {
    INSTANCE_OF: "P31",
    SUBCLASS_OF: "P279",
    PART_OF: "P361",
    ITEMS_ABOUT_ORGS: "",
    GENDER: "P21",
    COUNTRY_CITIZENSHIP: "P27",
    OCCUPATION: "P106",
    DEATH_DATE: "P570",
    SIBLING: "P3373",
    SPOUSE: "P26",
    CHILD: "P40",
  },
  property_types: {
    ABOUT_ORGS: "Q18608993",
    HUMAN_RELATIONSHIPS: "Q22964231",
    HUMAN_INFORMATION: "Q18608871",
  },
  categories: {
    ALUMNI: "P3876",
    EMPLOYEES: "P4195",
    AWARD_RECIPIENTS: "P2517",
    TEAM_MEMBERS: "P6112",
  },
}

WIKI_DATA.types.organization.ALL = flattenObjectValues(WIKI_DATA.types.organization);

WIKI_DATA.properties.organization = {
  _inclusive: [
    WIKI_DATA.properties.INSTANCE_OF,
    WIKI_DATA.properties.SUBCLASS_OF,
    WIKI_DATA.properties.PART_OF,
  ],
}

WIKI_DATA.properties.human = {
  _relational: [
    WIKI_DATA.properties.SIBLING,
    WIKI_DATA.properties.SPOUSE,
    WIKI_DATA.properties.CHILD,
  ],
}

// Load params that aren't necessary for display asynchronously through entity endpoint
export const CATEGORY = {
  REAL_HUMAN: {
    id: "Q5",
    label: "Real Humans",
    subclasses: false,
    filters: {
      ALIVE: { property: "P570", type: "[]", negation: true },
      DEAD: { property: "P570", type: "[]" },
      MALE: { property: "P21", type: "wd:Q6581097" },
      FEMALE: { property: "P21", type: "wd:Q6581072" },
    },
    params: [
      {
        field: "?gender",
        property: `P21`,
      },
    ],
  },
  FICTIONAL_HUMAN: {
    id: "Q15632617",
    label: "Fictional Humans",
    subclasses: true,
    params: [
      {
        field: "?gender",
        property: "P21",
      },
    ],
  },
  AWARD: { 
    id: "Q618779", 
    label: "Awards",
    subclasses: true,
    params: [
      {
        field: "?website",
        property: "P856",
      },
      {
        field: "?country",
        property: "P17",
      },
    ]
  },
  WORK: { id: "Q386724", label: "Works", subclasses: true },
  ORGANIZATION: { id: "Q43229", label: "Organizations", subclasses: true },
  POLITICAL_ORG: { id: "Q7210356", label: "Political Orgs", subclasses: true },
  COMPANY: { id: "Q783794", label: "Companies", subclasses: true },
  INSTITUTION: { id: "Q178706", label: "Institutions", subclasses: true },
  EDUCATIONAL: { id: "Q5341295", label: "Educational Orgs", subclasses: true },
}
