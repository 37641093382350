import React from 'react';
import { ForceGraph } from "./components/forceGraph";
import './App.css';

function App() {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const nodeHoverTooltip = React.useCallback((node) => {
    const header = node.name || `${node.source.name} & ${node.target.name}`;
    const subheader = node.name || !node.description ? "" : "<p><i>== Wiki extract ==</i></p>";
    return `<div>     
      <b>${header}</b>
      ${subheader}
      <p>${node.description||"<i>Couldn't parse relationship data, click entity icon to visit wikipedia page</i>"}</p>
    </div>`;
  }, []);

  const query = {
    search: params.s,
    entity: params.id,
    wiki: params.w,
  }

  return (
    <div className="App">
      <header className="App-header">
        <b>Blue Link Mapper</b>
        <small><i>Explore Wikipedia connections</i></small>
      </header>
      <section className="Main">
        <ForceGraph
          nodeHoverTooltip={nodeHoverTooltip}
          query={query}
        />
      </section>
    </div>
  );
}

export default App;
