import React from "react";
import { runForceGraph } from "./forceGraphGenerator";
import styles from "./forceGraph.module.css";

export function ForceGraph({ nodeHoverTooltip, query }) {
  const containerRef = React.useRef(null);

  React.useEffect(() => {
    const setupGraph = async () => {
      const data = await runForceGraph(containerRef.current, nodeHoverTooltip, query);
      return data;
    }

    let destroyFn;

    if (containerRef.current) {
      const { destroy } = setupGraph();
      destroyFn = destroy;
    }

    return destroyFn;
  }, []);

  return <div ref={containerRef} className={styles.container} />;
}
