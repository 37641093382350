import * as d3 from "d3";
import styles from "./forceGraph.module.css";
import { 
    NEUTRAL_COLOR, DEFAULT_COLOR, DEFAULT_ICON, 
    LINK_KEY_SEP, ICON_CODES,
    MARKER_POS_NORMAL, MARKER_POS_RELATIVE,
} from "./constants";

const getLinkKey = (node1, node2) => [node1.id, node2.id].sort().join(LINK_KEY_SEP);

const valueIfExists = (entity, field, accessor = (v) => v, def = "") => field in entity ? accessor(entity[field]) : def;

const getRandomInt = (min = 0, max) => Math.floor(Math.random() * (max - min)) + min;

export const choice = (values) => values[getRandomInt(0, values.length - 1)];

export const color = (d) => d.type ? ICON_CODES[d.type].color : DEFAULT_COLOR;

export const icon = (d) => d.type ? ICON_CODES[d.type].code : DEFAULT_ICON;

export const getClass = (d) => d.gender
    ? d.gender === "male"
        ? styles.male
        : styles.female
    : styles.neutral;

export const createLink = (source, target, description, relationship) => ({
    source: source,
    target: target,
    description: description,
    relationship: relationship,
    id: getLinkKey(source, target),
});

export const createNode = (data) => ({
    id: data.id,
    name: data.itemLabel.value,
    description: valueIfExists(data, "itemDescription", (d) => d.value),
    type: valueIfExists(data, "type", (d) => d.value),
    gender: valueIfExists(data, "genderLabel", (d) => d.value),
    article: valueIfExists(data, "article", (d) => d.value),
    stub: data.article ? lastOf(data.article.value.split("/")) : encodeURIComponent(data.itemLabel.value),
});

export function marker(svg, color) {
    const mid = `arrowhead-${color.replace("#", "")}`;
    const defs = svg.append("defs")
    defs.append("marker")
        .attr("id", mid)
        .attr("viewBox", "0 -5 10 10")
        .attr("refX", color !== NEUTRAL_COLOR ? MARKER_POS_RELATIVE : MARKER_POS_NORMAL)
        .attr("refY", 0)
        .attr("markerWidth", 15)
        .attr("markerHeight", 15)
        .attr("orient", "auto")
        .append("svg:path")
        .attr("fill", color)
        .attr("d", 'M0,-5L10,0L0,5');
    return `url(#${mid})`;
};

export const menuFactory = (x, y, menuItems, data, svgId, width, height) => {
    d3.select(`.${styles.contextMenu}`).remove();
    // Other interactions
    
    // Draw the menu
    d3.select(svgId)
        .append('g').attr('class', styles.contextMenu)
        .selectAll('tmp')
        .data(menuItems).enter()
        .append('g').attr('class', `${styles.menuEntry} ${styles.clickable}`)
        .style({'cursor': 'pointer'});

    // Draw menu entries
    d3.selectAll(`.${styles.menuEntry}`)
        .append('rect')
        // .attr('class', styles.menuContainer)
        .attr('x', x)
        .attr('y', (d, i) => { return y + (i * 30); })
        .attr('rx', 2)
        .attr('width', 150)
        .attr('height', 30)
        .attr('stroke', (d) => d.stroke || '#00344d')
        .attr('fill', (d) => d.fill || "#00557d")
        .on('click', (d) => { d.action(data) });

    d3.selectAll(`.${styles.menuEntry}`)
        .append('text')
        .text((d) => { return d.title; })
        .attr('x', x)
        .attr('y', (d, i) => { return y + (i * 30); })
        .attr('dy', 20)
        .attr('dx', 10)
        .on('click', (d) => { d.action(data) });

    // Other interactions
    d3.select('body')
        .on('click', () => {
            if(x !== d3.event.pageX - width / 2
            && y !== d3.event.pageY - height / 1.5)
            d3.select(`.${styles.contextMenu}`).remove();
        });
}

export const createContextMenu = (d, menuItems, width, height, svgId) => {
    menuFactory(d3.event.pageX - width / 2, d3.event.pageY - height / 1.5, menuItems, d, svgId, width, height);
    d3.event.preventDefault();
}

export const lastOf = (items) => items[items.length - 1];
