import * as d3 from "d3";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { generateNode, generateLinks, safeFetch } from "./wikiparser";
import { animateProgress, removeProgress, renderProgress } from "./progressBar";
import styles from "./forceGraph.module.css";
import { CATEGORY } from "./wikidata";
import { createContextMenu, color, icon, getClass, choice, createLink, createNode, marker, lastOf } from "./utils";
import { 
  BASE_SEARCH_URL, 
  ERROR_COLOR, NEUTRAL_COLOR,
  LINK_INFO_FONT_SIZE, LINK_STROKE_OPACITY, LINK_STROKE_WIDTH_DEFAULT, LINK_STROKE_WIDTH_RELATIONSHIP, 
  NODE_ICON_SIZE, NODE_LABEL_FONT_SIZE, NODE_LABEL_OFFSET, NODE_RADIUS, NODE_STROKE_COLOR, NODE_STROKE_WIDTH, CHARGE_FORCE, DEFAULT_NODE_IDS, ENKOSI_URL, WIKIDATA_SOURCE_IMG_URL,
  INFO_DATA,
} from "./constants";

let records = {};

let selectedCategory = CATEGORY.REAL_HUMAN;
let relativesOnly = false;

let progressData = null;
let currentSource = null;

const menuItems = [
  {
    title: "VIEW WIKI PAGE",
    fill: "#00344d",
    stroke: "#002738",
    action: (d) => {
      d.article
        ? window.open(d.article, '_blank').focus()
        : alert("No link available");
    }
  },
  {
    title: 'SELECT MODE:',
    fill: "#00344d",
    stroke: "#002738",
    action: (d) => {
      // TODO: add any action you want to perform
      selectedCategory = CATEGORY.REAL_HUMAN;
      relativesOnly = true;
      updateModeLabel(selectedCategory.label);
    }
  },
  {
    title: 'Relatives',
    action: (d) => {
      // TODO: add any action you want to perform
      selectedCategory = CATEGORY.REAL_HUMAN;
      relativesOnly = true;
      updateModeLabel(selectedCategory.label);
    }
  },
  {
    title: 'Humans (Real)',
    action: (d) => {
      // TODO: add any action you want to perform
      selectedCategory = CATEGORY.REAL_HUMAN;
      relativesOnly = false;
      updateModeLabel(selectedCategory.label);
    }
  },
  {
    title: 'Humans (Fictional)',
    action: (d) => {
      // TODO: add any action you want to perform
      selectedCategory = CATEGORY.FICTIONAL_HUMAN;
      relativesOnly = false;
      updateModeLabel(selectedCategory.label);
    }
  },
  {
    title: 'Organizations',
    action: (d) => {
      // TODO: add any action you want to perform
      selectedCategory = CATEGORY.ORGANIZATION;
      relativesOnly = false;
      updateModeLabel(selectedCategory.label);
    }
  },
  {
    title: 'Work',
    action: (d) => {
      // TODO: add any action you want to perform
      selectedCategory = CATEGORY.WORK;
      relativesOnly = false;
      updateModeLabel(selectedCategory.label);
    }
  },
  {
    title: 'Educational Orgs',
    action: (d) => {
      // TODO: add any action you want to perform
      selectedCategory = CATEGORY.EDUCATIONAL;
      relativesOnly = false;
      updateModeLabel(selectedCategory.label);
    }
  },
];


function updateModeLabel(label) {
  d3.select(`#${styles.modeDisplay}`)
    .style('color', color({ type: selectedCategory.label }))
    .text(`Mode: ${label}${relativesOnly ? " (relatives)" : ""}`);

  d3.selectAll('circle')
    .attr("fill", color)
}

async function getLinked(node_id, progressUpdater) {
  const source = records[node_id].node;
  const links = [];
  const nodes = [];

  const linkData = await generateLinks(node_id, source.stub, progressUpdater, selectedCategory, relativesOnly);

  for (const data of linkData) {
    let target = createNode(data);
    if (!(target.id in records)) {
      records[target.id] = {node: target, links: []};
      nodes.push(target);
    } else {
      target = records[target.id].node;
    }

    const link = Object.assign({}, createLink(source, target, data.linkDescription, data.ppropLabel));
    if (!(link.id in records)) {
      records[link.id] = link;
      links.push(link);
    }
    if (!records[target.id].links.includes(link.id)) {
      records[target.id].links.push(link.id);
    }
    if (!records[source.id].links.includes(link.id)) {
      records[source.id].links.push(link.id);
    }
  }

  return { nodes, links };
}

function runSim(sim, slink, slinkinfo, snode, snodeicon, snodelabel, offX = 0, offY = 0) {
  sim.on("tick", () => {
    //update link positions
    slink
      .attr("x1", d => d.source.x - offX)
      .attr("y1", d => d.source.y - offY)
      .attr("x2", d => d.target.x - offX)
      .attr("y2", d => d.target.y - offY);

    slinkinfo
      .attr("x", d => (d.source.x + d.target.x) * 0.5 - offX)
      .attr("y", d => (d.source.y + d.target.y) * 0.5 - offY);

    // update node positions
    snode
      .attr("cx", d => d.x - offX)
      .attr("cy", d => d.y - offY);

    snodeicon
      .attr("x", d => { return d.x - offX; })
      .attr("y", d => { return d.y - offY; });

    // update label positions
    snodelabel
      .attr("x", d => { return d.x - offX; })
      .attr("y", d => { return d.y - offY + NODE_LABEL_OFFSET; });
  }).alpha(1).restart();
}

export async function runForceGraph(
  container,
  nodeHoverTooltip,
  query,
) {
  const containerRect = container.getBoundingClientRect();
  const height = containerRect.height;
  const width = containerRect.width;

  let nodes = [];
  let links = [];

  if (query.wiki) {
    query.search = lastOf(query.wiki.split("/"));
  }
  if (!query.search) {
    const defaultNodeId = query.entity || choice(DEFAULT_NODE_IDS);
    const defaultNodeData = await generateNode(defaultNodeId);
    const defaultNode = createNode(defaultNodeData);
    nodes.push(defaultNode);
  }

  nodes.forEach((n) => records[n.id] = {"node": n, "links": []});
  links.forEach((l) => records[l.source]["source"].push(l));
  links.forEach((l) => records[l.target]["target"].push(l));

  const drag = (simulation) => {
    const dragstarted = (d) => {
      if (!d3.event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    };

    const dragged = (d) => {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    };

    const dragended = (d) => {
      if (!d3.event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    };

    return d3
      .drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  };

  // Add the tooltip element to the graph
  const tooltip = document.querySelector("#graph-tooltip");
  if (!tooltip) {
    const tooltipDiv = document.createElement("div");
    tooltipDiv.classList.add(styles.tooltip);
    tooltipDiv.style.opacity = "0";
    tooltipDiv.id = "graph-tooltip";
    document.body.appendChild(tooltipDiv);
  }
  const div = d3.select("#graph-tooltip");

  const addTooltip = (hoverTooltip, d, x, y) => {
    div
      .transition()
      .duration(200)
      .style("opacity", 0.9);
    div
      .html(hoverTooltip(d))
      .style("left", `${0}px`)
      .style("top", `${60}px`);
  };

  const removeTooltip = () => {
    div
      .transition()
      .duration(200)
      .style("opacity", 0);
  };

  const simulation = d3
    .forceSimulation(nodes)
    .force("link", d3.forceLink(links).id(d => d.id))
    .force("charge", d3.forceManyBody().strength(-1*CHARGE_FORCE))
    .force("centering", d3.forceCenter())
    .force("x", d3.forceX())
    .force("y", d3.forceY())
    .force('collide', d3.forceCollide(d => 65));

  const footer = d3
    .select(container)
    .append('div')
    .attr('id', styles.footer);

  footer
    .append('text')
    .attr('class', `${styles.footerText} ${styles.clickable}`)
    .text('Enkosi © 2022')
    .on('click', () => window.open(ENKOSI_URL, '_blank').focus());

  footer.append('img')
    .attr('width', 80)
    .attr('src', WIKIDATA_SOURCE_IMG_URL);

  const optionsBar = d3
    .select(container)
    .append('div')
    .attr('id', styles.optionsContainer)
    .append('div')
    .attr('id', styles.optionsBar);

  const helpOptions = optionsBar.append('div').attr('id', styles.helpOptions);

  helpOptions
    .append('text')
    .attr('id', styles.modeDisplay)
    .style('color', color({type: selectedCategory.label}))
    .text(`Mode: ${selectedCategory.label}${relativesOnly ? " (relatives)" : ""}`);

  helpOptions
    .append('text')
    .attr('id', styles.infoDisplay)
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'central')
    .attr("class", `fa info ${styles.clickable}`)
    .on('click', () => alert(INFO_DATA))
    .text(icon({ type: "_info" }));

  const inputContainer = optionsBar
    .append('div')
    .attr('class', styles.inputContainer);  

  inputContainer
    .append('input')
    .attr('class', styles.inputField)
    .attr('id', 'search-field')
    .attr('type', 'text')
    .attr('name', 'textInput')
    .attr('placeholder', 'Search Wikipedia!')
    .on('submit', () => console.log("Submit!"));

  inputContainer
    .append('button')
    .attr('class', styles.inputButton)
    .text('Search')
    .on("click", async () => {
      const search = d3.select("#search-field").node().value;
      runSearch(search);
    });

  const svg = d3
    .select(container)
    .append("svg")
    .attr("id", "graphSvg")
    .attr("viewBox", [-width / 2, -height / 2, width, height])
    .call(d3.zoom().on("zoom", function () {
      svg.attr("transform", d3.event.transform);
    }))
    .append("g")
    .attr("transform", "scale(.5)");

  function reset(new_nodes = null, new_links = null) {
    svg.selectAll("line").remove();
    svg.selectAll("circle").remove();
    svg.selectAll("text").remove();
    svg.selectAll("marker").remove();

    records = {};
    nodes = new_nodes || [];
    links = new_links || [];
    nodes.forEach((n) => records[n.id] = { "node": n, "links": [] });
    links.forEach((l) => records[l.source]["source"].push(l));
    links.forEach((l) => records[l.target]["target"].push(l));
  }

  async function runSearch(search) {
    const results = await safeFetch(
      `${BASE_SEARCH_URL}${encodeURIComponent(search)}`,
      (result) => result.query.search,
    );

    if (results && results.length > 0) {
      const nodeId = results[0].title;
      const searchedNodeData = await generateNode(nodeId);
      if (searchedNodeData) {
        const searchedNode = createNode(searchedNodeData);
        reset([searchedNode]);
        currentSource = searchedNode;
        const success = await downloadGraph(nodeId, nodes, links);
        if (!success) {
          alert(`No valid connections found for '${searchedNode.name}'`);
        }
      } else {
        alert(`We found a page for '${search}', but no valid data.\n\nThis could be a disambiguation page, which we can't handle quite yet!`);
      }
    } else {
      alert(`Can't find any matches on Wikipedia for '${search}'...\n\nAre you sure it's spelled correctly?`);
    }
  }

  async function downloadGraph(id, existing_nodes, existing_links) {
    progressData = renderProgress(width, height);

    const linked = await getLinked(
      id,
      (from = null, to = null, log = null) => animateProgress(progressData, width, height, from, to, log),
    );

    existing_nodes.push(...linked.nodes);
    existing_links.push(...linked.links.map((d) => Object.assign({}, d)));
    simulation.nodes(existing_nodes);
    simulation.force("link", d3.forceLink(existing_links).id(d => d.id));
    const out = update(existing_links, existing_nodes);

    runSim(
      simulation,
      out.link,
      out.linkinfo,
      out.node,
      out.nodeicon,
      out.nodelabel,
    );
    return (linked.links.length > 0 && linked.nodes.length > 0);
  }

  function update(new_links, new_nodes) {
    svg.selectAll("line").remove();
    svg.selectAll("circle").remove();
    svg.selectAll("text").remove();
    svg.selectAll("marker").remove();

    const link = svg
      .append("g")
      .attr("stroke-opacity", LINK_STROKE_OPACITY)
      .selectAll("line")
      .data(new_links)
      .join("line")
      .attr("stroke", (d) => d.relationship ? color(d) : NEUTRAL_COLOR)
      .attr("stroke-width", d => d.relationship ? LINK_STROKE_WIDTH_RELATIONSHIP : LINK_STROKE_WIDTH_DEFAULT)
      .attr("marker-end", d => marker(svg, d.relationship ? color(d) : NEUTRAL_COLOR));

    const linkinfo = svg
      .append("g")
      .attr("class", "linkinfo")
      .selectAll("text")
      .data(new_links)
      .enter()
      .append("text")
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .attr("class", `fa ${styles.info}`)
      .style('font-size', (d) => LINK_INFO_FONT_SIZE + (d.relationship ? 0 : 4))
      .style('text-transform', 'uppercase')
      .attr('fill', (d) => d.relationship ? color(d) : (d.description ? NEUTRAL_COLOR : ERROR_COLOR))
      .text(d => (d.relationship ? `${d.relationship.value} ` : "") + (d.description ? "\uf05a" : "\uf059"))
      .call(drag(simulation));

    linkinfo
      .on("mouseover", (d) => {
        addTooltip(nodeHoverTooltip, d, d3.event.pageX, d3.event.pageY);
      })
      .on("mouseout", () => {
        removeTooltip();
      });

    const node = svg
      .append("g")
      .attr("stroke", NODE_STROKE_COLOR)
      .attr("stroke-width", NODE_STROKE_WIDTH)
      .selectAll("circle")
      .data(new_nodes)
      .join("circle")
      .on('contextmenu', (d) => {
        createContextMenu(
          d,
          menuItems,
          width,
          height,
          '#graphSvg',
          d3.event.detail.pageX,
          d3.event.detail.pageY,
        );
      })
      .attr("r", NODE_RADIUS)
      .attr("fill", color)
      .call(drag(simulation));

    const nodeicon = svg.append("g")
      .attr("class", "nodelabels")
      .selectAll("text")
      .data(new_nodes)
      .enter()
      .append("text")
      .on("mouseover", (d) => {
        addTooltip(nodeHoverTooltip, d, d3.event.pageX, d3.event.pageY);
      })
      .on("mouseout", () => {
        removeTooltip();
      })
      .on("click", (d) => {
        createContextMenu(d, menuItems, width, height, '#graphSvg');
      })
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .style("font-size", NODE_ICON_SIZE)
      .attr("class", d => `fa ${styles.icon} ${styles.clickable}`)
      .text(icon)
      .call(drag(simulation));

    const nodelabel = svg.append("g")
      .attr("class", "nodelabels")
      .selectAll("text")
      .data(new_nodes)
      .enter()
      .append("text")
      .on('contextmenu', (d) => {
        createContextMenu(d, menuItems, width, height, '#graphSvg');
      })
      .attr('dy', NODE_LABEL_OFFSET)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .style('font-size', NODE_LABEL_FONT_SIZE)
      .attr("class", d => `fa ${getClass(d)} ${styles.clickable}`)
      .text(d => { return d.name; })  // .text(d => {return icon(d);})
      .call(drag(simulation));

    nodelabel.on("click", async (d) => {
      const selectedNode = simulation.find(d.x, d.y - NODE_LABEL_OFFSET);
      currentSource = selectedNode;
      downloadGraph(selectedNode.id, new_nodes, new_links, d.x, d.y);
    });

    removeProgress();
    return { link, linkinfo, node, nodeicon, nodelabel };
  }

  if (query.search) {
    runSearch(query.search);
  } else {
    const initialNode = choice(Object.values(records)).node;
    const { link, linkinfo, node, nodeicon, nodelabel } = update(
      [],
      [initialNode],
    );
    runSim(simulation, link, linkinfo, node, nodeicon, nodelabel);
  }

  return {
    destroy: () => {
      simulation.stop();
    },
    nodes: () => {
      return svg.node();
    }
  };
}
